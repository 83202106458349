.waiting {
    text-align: center;
    width: 100%;
}

.waiting-holder {
    height: 400px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.chart-holder {
    /* The whitespace poors over the 400px for some reason. Just tuck it behind. */
    z-index: -1;
    height: 400px;
}
