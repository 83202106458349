.nav-bar {
    background-color: black;
    padding: 1em 10%;
    display: flex;
    justify-content: left;
    align-items: center;
}

.nav-bar a {
    text-decoration: none;
    color: white;
    margin: 0 1em;
}

.title {
    color: white;
    font-size: 2rem;
    padding-right: 1em;
}
